import gameImport from './../game';

import joker from './../../images/game2/slot/img-joker.png';
import spadegaming from './../../images/game2/slot/img-spadegaming.png';
import fachai from './../../images/game2/slot/img-fachai.png';
import jili from './../../images/game2/slot/img-jili.png';
import redtiger from './../../images/game2/slot/img-redtiger.png';
import king from './../../images/game2/slot/img-kingmaker.png';
import pragmatic from './../../images/game2/slot/img-pragmaticplay.png';
import play8 from './../../images/game2/slot/img-play8.png';
import pegasus from './../../images/game2/slot/img-pegasus.png';
//import genesis from './../../images/game/slot/img-genesis.png';
import vpower from './../../images/game2/slot/img-vpower.png';
import fastspin from './../../images/game2/slot/img-fastspin.png';
import dragon from './../../images/game2/slot/img-dragon.png';
import nlc from './../../images/game2/slot/img-nolimitcity.png';
//import yesgame from './../../images/game2/slot/v-sport-yes-gaming-s-min.png';
import jdbslot from './../../images/game2/slot/v-sport-jdb-s-min.png';
//import idg from './../../images/game2/slot/v-sport-IDG-s-min.png';
import ygr from './../../images/game2/slot/img-ygr-d.png';

var gameMenu = {
  name: 'SLOT',
  items: [
    {
      name: 'PEGAGUS',
      isFullWidth: false,
      provider: 'pegasus',
      icon: gameImport.find((x) => x.code.toString() === '80').icon,
      code: '80-201',
      img: [{ langId: 0, src: pegasus }],
      img2: [{ langId: 0, src: pegasus }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'JOKER',
      isFullWidth: false,
      provider: 'joker',
      icon: gameImport.find((x) => x.code.toString() === '35').icon,
      code: '35',
      img: [{ langId: 0, src: joker }],
      img2: [{ langId: 0, src: joker }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'VPOWER',
      provider: 'Vpower',
      icon: gameImport.find((x) => x.code.toString() === '72').icon,
      code: '72-101',
      img: [{ langId: 0, src: vpower }],
      img2: [{ langId: 0, src: vpower }],
      labelName: '',
      parentName: '',
    },
    {
      name: 'SPADE GAMING',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-205',
      img: [{ langId: 0, src: spadegaming }],
      img2: [{ langId: 0, src: spadegaming }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'FA CHAI',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-207',
      img: [{ langId: 0, src: fachai }],
      img2: [{ langId: 0, src: fachai }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'JILI',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-208',
      img: [{ langId: 0, src: jili }],
      img2: [{ langId: 0, src: jili }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'RED TIGER',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-202',
      img: [{ langId: 0, src: redtiger }],
      img2: [{ langId: 0, src: redtiger }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'KING MAKER',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-204',
      img: [{ langId: 0, src: king }],
      img2: [{ langId: 0, src: king }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'PRAGMATIC PLAY',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-203',
      img: [{ langId: 0, src: pragmatic }],
      img2: [{ langId: 0, src: pragmatic }],
      isNew: false,
      labelName: '',
      parentName: '',
    },

    {
      name: 'PLAY8',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-210',
      img: [{ langId: 0, src: play8 }],
      img2: [{ langId: 0, src: play8 }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    // {
    //   name: 'GENESIS',
    //   provider: 'ebet',
    //   icon: gameImport.find((x) => x.code.toString() === '64').icon,
    //   code: '64-101',
    //   img: [{ langId: 0, src: genesis }],
    //   img2: [{ langId: 0, src: genesis }],
    //   labelName: '',
    //   parentName: '',
    // },
    {
      name: 'FAST SPIN',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-212',
      img: [{ langId: 0, src: fastspin }],
      img2: [{ langId: 0, src: fastspin }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'DRAGOON SOFT',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-213',
      img: [{ langId: 0, src: dragon }],
      img2: [{ langId: 0, src: dragon }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'NO LIMIT CITY',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-214',
      img: [{ langId: 0, src: nlc }],
      img2: [{ langId: 0, src: nlc }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    // {
    //   name: 'iDealGaming',
    //   isFullWidth: false,
    //   provider: 'iDealGaming',
    //   icon: gameImport.find((x) => x.code.toString() === '100').icon,
    //   code: '100',
    //   img: [{ langId: 0, src: idg }],
    //   img2: [{ langId: 0, src: idg }],
    //   isNew: false,
    //   labelName: '',
    //   parentName: '',
    // },
    {
      name: 'JDB SLOT',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-201',
      img: [{ langId: 0, src: jdbslot }],
      img2: [{ langId: 0, src: jdbslot }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'YGR',
      isFullWidth: false,
      provider: 'YGR',
      icon: gameImport.find((x) => x.code.toString() === '101').icon,
      code: '101',
      img: [{ langId: 0, src: ygr }],
      img2: [{ langId: 0, src: ygr }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
  ],
};

export default gameMenu;
